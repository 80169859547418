import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Particle from "../Particle";
import Home2 from "./Home2";

import Type from "./Type";


function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <h1></h1>
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">

              <h1 className="heading-name">

                <strong className="main-name"> Brian Haney</strong>
              </h1>
              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            <Col>
              <h2>
               About <strong className="main-name">Me </strong>
              </h2>
              <p>
                I'm an American Inventor, Programmer, Attorney, and Entrepreneur. I'm currently working on inventions and products in Web3, biotechnology, and aerospace. My main invention in biotechnology is a synthetic thyroid, which I invented as a cure to the disease I was born with, hypothyroidism. In aerospace, I have been working on autonomous rocket and space technologies. In blockchain, my work is focused on decentralized decision making and compliance technologies. 

              </p>
            </Col>

          </Row>



        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
